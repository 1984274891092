import db from '../db';

class Correction {
  builder() {
    return db.correction;
  }

  insert(item) {
    return this.builder().put(item);
  }

  insertBulk(rows) {
    return this.builder().bulkPut(rows);
  }

  remove(id) {
    return this.builder().delete(id);
  }

  clear() {
    return this.builder().clear();
  }

  findByProductId(id) {
    return this.builder()
      .where('product_id')
      .equals(id)
      .first();
  }
}

const correctionRepository = new Correction();

export default correctionRepository;
