export const FETCH = 'results/FETCH';
export const RECEIVE = 'results/RECEIVE';
export const CHANGE_PAGE = 'results/CHANGE_PAGE';
export const CHANGE_SORT = 'results/CHANGE_SORT';
export const CHANGE_FILTER = 'results/CHANGE_FILTER';

export default {
  FETCH,
  RECEIVE,
  CHANGE_PAGE,
  CHANGE_SORT,
  CHANGE_FILTER
};
