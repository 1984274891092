import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DataTable from '.';

export default class PaginatedDataTable extends Component {
  static propTypes = {
    data: PropTypes.array,
    length: PropTypes.number.isRequired
  };

  state = {
    currentPage: 0
  };

  handlePageChange = currentPage => {
    this.setState({ currentPage });
  };

  render() {
    const { data, length, ...rest } = this.props;
    const { currentPage } = this.state;

    let paginatedData = null;

    if (data) {
      paginatedData = data.slice(currentPage * length, (currentPage + 1) * length);
    }

    return (
      <DataTable
        data={paginatedData}
        page={currentPage}
        length={length}
        onPageChange={this.handlePageChange}
        totalCount={data && data.length}
        {...rest}
      />
    );
  }
}
