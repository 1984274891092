import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Grid, LinearProgress, Paper, Typography } from '@material-ui/core';
import OfflineIcon from '@material-ui/icons/CloudOff';

import { selectors as appSelectors } from 'redux/modules/app';

const MessageContainer = styled(({ visible, ...props }) => <Paper elevation={3} square {...props} />)`
  width: 100%;
  height: ${props => (props.visible ? '32px' : '0')};
  transition: height 0.2s;
  overflow: hidden;
`;

const Message = styled.div`
  padding: 5px 5px 0;
`;

const StatusIconContainer = styled.span`
  float: left;
  margin-right: 5px;
`;

const StyledOfflineIcon = styled(OfflineIcon)`
  width: 16px !important;
  height: 16px !important;
`;

export class BackgroundLoader extends Component {
  static propTypes = {
    productsData: PropTypes.object.isRequired
  };

  render() {
    const { productsData } = this.props;

    const progress = productsData.fetching ? ((productsData.cursor || 0) / (productsData.lastCursor || 1)) * 100 : 100;

    return (
      <MessageContainer visible={productsData.fetching && !productsData.error}>
        <Message>
          <LinearProgress
            mode="determinate"
            value={progress}
            style={{ width: '100%' }}
            color={productsData.error ? 'error' : 'primary'}
          />
          <Grid container>
            <Grid item xs={6}>
              <Typography align="left" variant="body1">
                <StatusIconContainer>{productsData.offline ? <StyledOfflineIcon /> : null}</StatusIconContainer>
                Aktualisiere Stammdaten...
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right" variant="body1">
                {parseInt(progress, 0)} %
              </Typography>
            </Grid>
          </Grid>
        </Message>
      </MessageContainer>
    );
  }
}

const mapState = state => ({
  productsData: appSelectors.getProductsData(state)
});

export default connect(mapState)(BackgroundLoader);
