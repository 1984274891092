import Dexie from 'dexie';

import { default as actions } from './actions';
import { default as selectors } from './selectors';
import {
  getProducts,
  getInventories,
  uploadFinalData,
  getStock,
  validateEmployeeAuth,
  getItems,
  getCorrections
} from 'app/api';
import productsRepository from 'app/db/model/products';
import { default as inventoryActions } from 'redux/modules/inventory/actions';
import { MSG_ERROR_CONNECTION, MSG_ERROR_INTERNAL, MSG_ERROR_CONFLICT, MSG_ERROR_GONE } from 'config/constants';
import scannedItemsRepository from 'app/db/model/scanned-items';
import scannedProductsRepository from 'app/db/model/scanned-products';
import { syncResults } from 'redux/modules/results/operations';
import stockRepository from '../../../app/db/model/stock';
import correctionRepository from '../../../app/db/model/correction';
import { getActiveInventory } from './selectors';
import resultsRepository from '../../../app/db/model/results';
import dbWorker from '../../../app/db/db-worker';
import history from '../../../config/history';

export const init = actions.init;
export const dbUpdate = actions.dbUpdate;
export const swUpdateAvailable = actions.swUpdateAvailable;
export const confirmStockListSeen = actions.confirmStockListSeen;

export const start = inventory => dispatch => {
  history.push('/');
  dispatch(actions.start(inventory));
  dispatch(loadCurrentInventoryData());
};

export const loadCurrentInventoryData = () => dispatch => {
  dispatch(syncResults());
  // commented out since stock diff list is not used for now, might need to be used later
  // dispatch(fetchStock());
  // dispatch(fetchItems());
  // dispatch(fetchCorrections());
};

export const finish = () => async (dispatch, getState) => {
  const state = getState();
  const activeInventory = selectors.getActiveInventory(state);

  dispatch(actions.fetchUpload());

  const inventoryData = await Promise.all([
    scannedItemsRepository.builder().toArray(),
    correctionRepository.builder().toArray()
  ]).then(values => {
    const items = values[0];
    const corrections = values[1];

    const newItems = [];
    for (const item of items) {
      if (!item.final) {
        newItems.push(item);
      }
    }

    return {
      items: newItems,
      corrections: corrections
    };
  });

  if (!inventoryData.items.length && !inventoryData.corrections.length) {
    dispatch(actions.receiveUpload());
    dispatch(displayAlert('Inventur erfolgreich!', 'Die Inventurdaten wurden übertragen. Vielen Dank!'));

    dispatch(cleanup());
    return;
  }

  return uploadFinalData(activeInventory, inventoryData)
    .then(async response => {
      switch (response.status) {
        case 200:
          dispatch(actions.receiveUpload());
          dispatch(displayAlert('Inventur erfolgreich!', 'Die Inventurdaten wurden übertragen. Vielen Dank!'));

          dispatch(cleanup());
          break;

        case 409:
          dispatch(errorUpload(MSG_ERROR_CONFLICT, true));
          break;

        case 410:
          dispatch(errorUpload(MSG_ERROR_GONE, true));
          break;

        default:
          dispatch(errorUpload(MSG_ERROR_INTERNAL));
      }
    })
    .catch(error => {
      console.error(error);
      dispatch(errorUpload(MSG_ERROR_CONNECTION));
    });
};

export const authEmployee = value => dispatch => {
  dispatch(actions.employeeAuthStart());

  validateEmployeeAuth(value)
    .then(response => {
      if (response.ok) {
        dispatch(actions.employeeAuthFinish(value));
      } else {
        dispatch(actions.employeeAuthError('Authentifizierungsfehler.'));
      }
    })
    .catch(() => {
      dispatch(actions.employeeAuthError('Authentifizierungsfehler.'));
    });
};

export const errorUpload = (message, confirmable) => dispatch => {
  dispatch(actions.errorUpload(message, confirmable));
};

export const cleanup = () => async dispatch => {
  await Promise.all([
    scannedItemsRepository.clear(),
    scannedProductsRepository.clear(),
    clearResults(),
    clearStockDatabase(),
    clearCorrectionsDatabase()
  ]).then(() => {
    dispatch(inventoryActions.clearItems());
  });

  dispatch(actions.finish());
  dispatch(actions.clearInventories());
  dispatch(fetchInventories());
};

export const clearResults = () => async dispatch => {
  resultsRepository.clear();
};

export const clearStockDatabase = () => async dispatch => {
  await stockRepository.clear();
  dispatch(actions.clearStock());
};

export const clearCorrectionsDatabase = () => async dispatch => {
  await correctionRepository.clear();
  dispatch(actions.clearCorrections());
};

export const clearProducts = () => async dispatch => {
  await productsRepository.clear();
  dispatch(actions.clearProducts());
};

export const fetchProducts = () => (dispatch, getState) => {
  dispatch(actions.fetchProducts());

  const state = getState();
  const { lastCursor } = selectors.getProductsData(state);

  if (!lastCursor) {
    dispatch(clearProducts());
  }

  let retryCount = 0;

  const fetcher = async () => {
    try {
      const state = getState();

      let cursor = selectors.getProductsData(state).cursor;

      while (true) {
        const result = await getProducts(cursor).then(response => {
          if (response.ok) {
            return response.json();
          }

          dispatch(
            actions.fetchProductsError(
              `Fehler beim Laden der Stammdaten. Server Fehler ${response.status}! Bitte später nochmal versuchen.`
            )
          );
        });

        if (result === undefined) {
          return;
        }

        try {
          await dbWorker.insertBulk('products', result.products);
        } catch (error) {
          if (error instanceof Dexie.BulkError) {
            let quotaError = false;

            error.failures.forEach(failure => {
              if (failure instanceof Dexie.QuotaExceededError) {
                quotaError = true;
              }
            });

            if (quotaError) {
              dispatch(
                actions.fetchProductsError(
                  'Fehler beim Laden der Stammdaten. Die Anwendung verfügt nicht über genügend Speicherplatz.'
                )
              );

              return;
            }
          }

          dispatch(actions.fetchProductsError('Datenbank Fehler beim Laden der Stammdaten.'));

          return;
        }

        if (!result.nextCursor) {
          dispatch(actions.updateProductsFinished());
          return;
        }

        cursor = result.nextCursor;

        dispatch(actions.receiveProducts(cursor, result.lastCursor));

        retryCount = 0;
      }
    } catch (error) {
      dispatch(actions.fetchProductsOffline());

      retryCount++;

      if (retryCount > 8) {
        dispatch(
          actions.fetchProductsError(
            'Fehler beim Laden der Stammdaten. Bitte Internetverbindung prüfen und nur in der Filiale verbinden.'
          )
        );

        return;
      }

      setTimeout(() => {
        fetcher();
      }, retryCount * retryCount * 1000);
    }
  };

  fetcher();
};

export const fetchInventories = () => dispatch => {
  dispatch(actions.fetchInventories());

  getInventories()
    .then(data => {
      dispatch(actions.receiveInventories(data.inventories));
    })
    .catch(error => {
      console.error(error);
      dispatch(actions.fetchInventoriesError());
    });
};

export const fetchStock = () => (dispatch, getState) => {
  const inventory = getActiveInventory(getState());

  dispatch(actions.fetchStockStart());

  getStock(inventory)
    .then(async products => {
      await dbWorker.insertBulk('stock', products);
      dispatch(actions.fetchStockFinish());
    })
    .catch(error => {
      console.error(error);
      dispatch(actions.fetchStockError());
    });
};

export const fetchCorrections = () => (dispatch, getState) => {
  const inventory = getActiveInventory(getState());

  dispatch(actions.fetchCorrectionsStart());

  getCorrections(inventory)
    .then(async corrections => {
      await dbWorker.insertBulk('correction', corrections);
      dispatch(actions.fetchCorrectionsFinish());
    })
    .catch(error => {
      console.error(error);
      dispatch(actions.fetchCorrectionsError());
    });
};

export const fetchItems = () => (dispatch, getState) => {
  const inventory = getActiveInventory(getState());

  dispatch(actions.fetchItemsStart());

  getItems(inventory)
    .then(async data => {
      const items = [];
      const productsMap = {};

      for (const row of data) {
        items.push({
          ...row,
          synced: true,
          final: true
        });

        if (!(row.product.id in productsMap)) {
          productsMap[row.product.id] = {
            id: row.product.id,
            parma_number: row.product.parma_number,
            ean: row.product.ean,
            full_description: row.product.full_description,
            amount: 0
          };
        }

        productsMap[row.product.id].amount += 1;
        productsMap[row.product.id].scan_date = row.date;
      }

      await dbWorker.insertBulk('scanned_items', items);
      await dbWorker.insertBulk('scanned_products', Object.values(productsMap));
      dispatch(actions.fetchItemsFinish());
    })
    .catch(error => {
      console.error(error);
      dispatch(actions.fetchCorrectionsError());
    });
};

export const forceDeleteData = () => dispatch => {
  dispatch(dismissUploadError());
  dispatch(cleanup());
};

export const dismissUploadError = actions.dismissUploadError;
export const displayAlert = actions.displayAlert;
export const confirmAlert = actions.confirmAlert;

export default {
  init,
  dbUpdate,
  swUpdateAvailable,
  start,
  finish,
  authEmployee,
  errorUpload,
  cleanup,
  fetchProducts,
  fetchInventories,
  forceDeleteData,
  dismissUploadError,
  displayAlert,
  confirmAlert,
  confirmStockListSeen
};
