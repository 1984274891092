import actions from './actions';
import selectors from './selectors';
import correctionRepository from '../../../app/db/model/correction';
// eslint-disable-next-line import/no-webpack-loader-syntax
import stockWorker from 'workerize-loader!./worker';
import { getEmployee } from '../app/selectors';
import { config as tableConfig } from '../../../modules/stock-overview/table/config';

const worker = stockWorker();

const filterDataSet = (dataSet, state) => {
  const { columns } = tableConfig;
  const filter = selectors.getFilter(state);

  if (!filter.search) {
    return dataSet;
  }

  return dataSet.filter(item => {
    if (!filter.property) {
      for (const column of columns) {
        if (
          String(item[column.property])
            .toUpperCase()
            .search(filter.search.toUpperCase()) > -1
        ) {
          return true;
        }
      }

      return false;
    }

    return (
      String(item[filter.property])
        .toUpperCase()
        .search(filter.search.toUpperCase()) > -1
    );
  });
};

export const fetch = () => async (dispatch, getState) => {
  const { columns } = tableConfig;
  const state = getState();

  dispatch(actions.fetch());

  const page = selectors.getPage(state);
  const length = selectors.getLength(state);
  const sort = selectors.getSort(state);

  const dataSet = await worker.generateStockDataSet();
  let rows = filterDataSet(dataSet, state);

  if (sort.length) {
    const sortColumn = columns[sort[0].column].property;
    const sortDir = sort[0].dir === 'desc' ? 1 : -1;

    rows.sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) {
        return sortDir;
      }

      if (a[sortColumn] > b[sortColumn]) {
        return sortDir * -1;
      }

      return 0;
    });
  }

  const data = {
    recordsTotal: dataSet.length,
    recordsFiltered: rows.length,
    data: rows.slice(page * length, (page + 1) * length)
  };

  dispatch(actions.receive(data));
};

export const changePage = page => dispatch => {
  dispatch(actions.changePage(page));
  dispatch(fetch());
};

export const changePageLength = length => dispatch => {
  dispatch(actions.changePageLength(length));
  dispatch(fetch());
};

export const changeSort = index => dispatch => {
  dispatch(actions.changeSort(index));
  dispatch(fetch());
};

export const changeFilter = (search, property) => (dispatch, getState) => {
  const prevSearch = selectors.getFilterSearch(getState());

  dispatch(actions.changeFilter(search, property));

  if (!search && search === prevSearch) {
    return;
  }

  dispatch(fetch());
  dispatch(fetchStatistics());
};

export const changeCorrection = (product_id, qty) => (dispatch, getState) => {
  const employee = getEmployee(getState());
  const employeeId = parseInt(employee.number, 10);

  qty = isNaN(qty) ? null : qty;

  correctionRepository.insert({
    product_id,
    qty,
    employee_id: employeeId
  });

  dispatch(actions.changeColumnData('product_id', product_id, 'corrected_qty', qty));
};

export const fetchStatistics = () => async (dispatch, getState) => {
  const state = getState();

  dispatch(actions.fetchStatisticsStart());

  try {
    const dataSet = await worker.getStockStatisticsDataSet();
    const rows = filterDataSet(dataSet, state);
    const data = {
      stock: 0,
      price: 0,
      purchase_price: 0,
      stock_diff: 0,
      purchase_price_diff: 0
    };

    for (const row of rows) {
      data.stock += row.stock_qty;
      data.price += row.price * row.stock_qty;
      data.purchase_price += row.purchase_price * row.stock_qty;
      data.stock_diff += row.diff_qty;
      data.purchase_price_diff += row.diff_purchase_price;
    }

    dispatch(actions.fetchStatisticsFinish(data));
  } catch (error) {
    console.error(error);
    dispatch(actions.fetchStatisticsError(error));
  }
};

export default {
  fetch,
  changePage,
  changePageLength,
  changeSort,
  changeFilter,
  changeCorrection,
  fetchStatistics
};
