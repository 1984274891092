export const INIT = 'app/INIT';
export const DB_UPDATE = 'app/DB_UPDATE';
export const SW_UPDATE_AVAILABLE = 'app/SW_UPDATE_AVAILABLE';
export const START = 'app/START';
export const FINISH = 'app/FINISH';
export const EMPLOYEE_AUTH_START = 'app/EMPLOYEE_AUTH_START';
export const EMPLOYEE_AUTH_FINISH = 'app/EMPLOYEE_AUTH_FINISH';
export const EMPLOYEE_AUTH_ERROR = 'app/EMPLOYEE_AUTH_ERROR';
export const CLEAR_PRODUCTS = 'app/CLEAR_PRODUCTS';
export const FETCH_PRODUCTS = 'app/FETCH_PRODUCTS';
export const RECEIVE_PRODUCTS = 'app/RECEIVE_PRODUCTS';
export const UPDATE_PRODUCTS_FINISHED = 'app/UPDATE_PRODUCTS_FINISHED';
export const FETCH_PRODUCTS_OFFLINE = 'app/FETCH_PRODUCTS_OFFLINE';
export const FETCH_PRODUCTS_ERROR = 'app/FETCH_PRODUCTS_ERROR';
export const FETCH_INVENTORIES = 'app/FETCH_INVENTORIES';
export const RECEIVE_INVENTORIES = 'app/RECEIVE_INVENTORIES';
export const FETCH_INVENTORIES_ERROR = 'app/FETCH_INVENTORIES_ERROR';
export const CLEAR_INVENTORIES = 'app/CLEAR_INVENTORIES';
export const FETCH_STOCK_START = 'app/FETCH_STOCK_START';
export const FETCH_STOCK_FINISH = 'app/FETCH_STOCK_FINISH';
export const FETCH_STOCK_ERROR = 'app/FETCH_STOCK_ERROR';
export const CLEAR_STOCK = 'app/CLEAR_STOCK';
export const FETCH_ITEMS_START = 'app/FETCH_ITEMS_START';
export const FETCH_ITEMS_FINISH = 'app/FETCH_ITEMS_FINISH';
export const FETCH_ITEMS_ERROR = 'app/FETCH_ITEMS_ERROR';
export const CLEAR_ITEMS = 'app/CLEAR_ITEMS';
export const FETCH_CORRECTIONS_START = 'app/FETCH_CORRECTIONS_START';
export const FETCH_CORRECTIONS_FINISH = 'app/FETCH_CORRECTIONS_FINISH';
export const FETCH_CORRECTIONS_ERROR = 'app/FETCH_CORRECTIONS_ERROR';
export const CLEAR_CORRECTIONS = 'app/CLEAR_CORRECTIONS';
export const FETCH_UPLOAD = 'app/FETCH_UPLOAD';
export const RECEIVE_UPLOAD = 'app/RECEIVE_UPLOAD';
export const ERROR_UPLOAD = 'app/ERROR_UPLOAD';
export const DISMISS_UPLOAD_ERROR = 'app/DISMISS_UPLOAD_ERROR';
export const DISPLAY_ALERT = 'app/DISPLAY_ALERT';
export const CONFIRM_ALERT = 'app/CONFIRM_ALERT';
export const CONFIRM_STOCK_LIST_SEEN = 'app/CONFIRM_STOCK_LIST_SEEN';

export default {
  INIT,
  DB_UPDATE,
  SW_UPDATE_AVAILABLE,
  START,
  FINISH,
  EMPLOYEE_AUTH_START,
  EMPLOYEE_AUTH_FINISH,
  EMPLOYEE_AUTH_ERROR,
  CLEAR_PRODUCTS,
  FETCH_PRODUCTS,
  RECEIVE_PRODUCTS,
  UPDATE_PRODUCTS_FINISHED,
  FETCH_PRODUCTS_OFFLINE,
  FETCH_PRODUCTS_ERROR,
  FETCH_INVENTORIES,
  RECEIVE_INVENTORIES,
  FETCH_INVENTORIES_ERROR,
  CLEAR_INVENTORIES,
  FETCH_STOCK_START,
  FETCH_STOCK_FINISH,
  FETCH_STOCK_ERROR,
  CLEAR_STOCK,
  FETCH_ITEMS_START,
  FETCH_ITEMS_FINISH,
  FETCH_ITEMS_ERROR,
  CLEAR_ITEMS,
  FETCH_CORRECTIONS_START,
  FETCH_CORRECTIONS_FINISH,
  FETCH_CORRECTIONS_ERROR,
  CLEAR_CORRECTIONS,
  FETCH_UPLOAD,
  RECEIVE_UPLOAD,
  ERROR_UPLOAD,
  DISMISS_UPLOAD_ERROR,
  DISPLAY_ALERT,
  CONFIRM_ALERT,
  CONFIRM_STOCK_LIST_SEEN
};
