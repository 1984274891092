import React from 'react';
import styled, { css } from 'styled-components';
import BaseTableRow from '@material-ui/core/TableRow';
import BaseTableCell from '@material-ui/core/TableCell';
import { Paper } from '@material-ui/core';

export const InfoColumn = styled(BaseTableCell)`
  text-align: center !important;
  border-bottom: 0 !important;
`;

export const DefaultWrapper = ({ children }) => (
  <Paper elevation={3} square>
    {children}
  </Paper>
);

export const TableContainer = styled.div`
  position: relative;
`;

export const TableScroller = styled.div`
  overflow-x: auto;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 57px;
  right: 0;
  bottom: ${props => (props.footerPadding ? '54px' : 0)};
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.palette.common.lightWhite};
`;

export const TableRow = styled(BaseTableRow)``;

export const TableCell = styled(({ centered, width, ...props }) => <BaseTableCell {...props} />)`
  width: ${props => props.width || 'auto'} !important;

  ${props =>
    props.centered &&
    css`
      text-align: center !important;
    `};
`;

export const BodyTableCell = styled(TableCell)`
  white-space: normal !important;
`;
