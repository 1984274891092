import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Button, Grid, List, ListItem, ListItemText, MenuItem, TextField } from '@material-ui/core';

import { operations as manualCountOperations, selectors as manualCountSelectors } from 'redux/modules/manual-count';
import { operations as appOperations } from 'redux/modules/app';
import { PRODUCT_GROUPS } from 'config/constants';
import FullheightMenu from 'modules/util/fullheight-menu';
import AmountSelection from 'modules/manual-count/general/amount-selection';
import ReductionOptions from 'modules/manual-count/general/reduction-options';

const FullWidthButton = styled(Button)`
  width: 100%;
`;

export class NewItem extends Component {
  static propTypes = {
    next: PropTypes.object.isRequired,
    changeNext: PropTypes.func
  };

  state = {
    groupMenuAnchor: undefined,
    groupMenuOpen: false
  };

  handleGroupButtonClick = e => {
    this.setState({ groupMenuOpen: true, groupMenuAnchor: e.currentTarget });
  };

  handleGroupItemClick = (e, key) => {
    const { changeNext } = this.props;

    changeNext && changeNext({ group: key });

    this.setState({ groupMenuOpen: false });
  };

  handleGroupRequestClose = () => {
    this.setState({ groupMenuOpen: false });
  };

  handleNameChange = e => {
    const { changeNext } = this.props;

    changeNext && changeNext({ name: e.target.value });
  };

  handlePriceChange = e => {
    const { changeNext } = this.props;
    const value = parseFloat(e.target.value);

    changeNext && changeNext({ price: value });
  };

  handleAmountChange = value => {
    const { changeNext } = this.props;

    changeNext && changeNext({ amount: value });
  };

  handleReductionChange = newReduction => {
    const { changeNext } = this.props;

    changeNext && changeNext({ reduction: newReduction });
  };

  handleSubmit = () => {
    const { next, submitNext, onAlert } = this.props;

    let message = null;

    if (!next.amount) {
      message = 'Bitte Anzahl angeben!';
    }

    if (!next.price) {
      message = 'Bitte Preis angeben!';
    }

    if (!next.name) {
      message = 'Bitte Artikelbeschreibung angeben!';
    }

    if (!next.group) {
      message = 'Bitte Produktgruppe auswählen!';
    }

    if (message) {
      onAlert('Fehler!', message);
      return;
    }

    submitNext && submitNext(next);
  };

  render() {
    const { next } = this.props;

    return (
      <div>
        <List>
          <ListItem button disableGutters onClick={this.handleGroupButtonClick}>
            <ListItemText primary="Warengruppe" secondary={PRODUCT_GROUPS[next.group]} />
          </ListItem>
        </List>
        <FullheightMenu
          anchorEl={this.state.groupMenuAnchor}
          open={this.state.groupMenuOpen}
          onClose={this.handleGroupRequestClose}
        >
          {Object.entries(PRODUCT_GROUPS).map(([key, value]) => {
            key = parseInt(key, 10);

            return (
              <MenuItem
                key={key}
                selected={next.group === key}
                onClick={event => this.handleGroupItemClick(event, key)}
              >
                {value}
              </MenuItem>
            );
          })}
        </FullheightMenu>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Artikelbeschreibung"
                value={next.name || ''}
                onChange={this.handleNameChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="number"
                label="Preis (€)"
                value={next.price || ''}
                onChange={this.handlePriceChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
        <AmountSelection amount={next.amount} onChange={this.handleAmountChange} />
        <ReductionOptions reduction={next.reduction} onChange={this.handleReductionChange} />
        <div>
          <FullWidthButton variant="contained" color="primary" onClick={this.handleSubmit}>
            Hinzufügen
          </FullWidthButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    next: manualCountSelectors.getManualNext(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeNext: values => {
      dispatch(manualCountOperations.manualChangeNext(values));
    },
    submitNext: next => {
      dispatch(manualCountOperations.manualSubmit(next));
    },
    onAlert: (title, message) => {
      dispatch(appOperations.displayAlert(title, message));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewItem);
