import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';

const styles = {
  paper: {
    maxHeight: '80vh'
  }
};

const FullheightMenu = props => {
  const { classes, children, ...rest } = props;

  return (
    <Menu classes={classes} {...rest}>
      {children}
    </Menu>
  );
};

export default withStyles(styles)(FullheightMenu);
