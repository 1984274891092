import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { connect } from 'react-redux';

import { getLastItem } from '../../redux/modules/inventory/selectors';
import { updateLastItemAmount } from '../../redux/modules/inventory/operations';
import styles from './lastItem.module.css';

const ChangeAmountDialog = ({ amount, open, onClose, onSave }) => {
  const [newAmount, setNewAmount] = useState(amount);

  useEffect(() => {
    if (amount !== newAmount && open) {
      setNewAmount(amount);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, open]);

  const safeAdjustNewAmount = adjustmentValue => {
    let nextAmount = newAmount + adjustmentValue;

    if (nextAmount < 1) {
      nextAmount = 1;
    }

    setNewAmount(nextAmount);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Gescannte Anzahl ändern</DialogTitle>
      <DialogContent>
        <Grid container justify="center" spacing={0}>
          <Grid item md={4} xs={12} container justify="center">
            <Grid item className={styles.quickAdjustButtonGroupContainer}>
              <ButtonGroup variant="outlined" size="small" fullWidth>
                <Button onClick={() => safeAdjustNewAmount(-100)}>-100</Button>
                <Button onClick={() => safeAdjustNewAmount(-10)}>-10</Button>
                <Button onClick={() => safeAdjustNewAmount(-1)}>-1</Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid item md={4} xs={12} container justify="center">
            <Grid item>
              <TextField
                label="Anzahl"
                value={newAmount}
                onChange={event => setNewAmount(event.target.value)}
                inputProps={{ min: 1 }}
                type="number"
                className={styles.changeAmountTextField}
              />
            </Grid>
          </Grid>
          <Grid item md={4} xs={12} container justify="center">
            <Grid item className={styles.quickAdjustButtonGroupContainer}>
              <ButtonGroup variant="outlined" size="small" fullWidth>
                <Button onClick={() => safeAdjustNewAmount(1)}>+1</Button>
                <Button onClick={() => safeAdjustNewAmount(10)}>+10</Button>
                <Button onClick={() => safeAdjustNewAmount(100)}>+100</Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose()}>
          Abbrechen
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!newAmount || newAmount < 1}
          onClick={() => onSave(newAmount)}
        >
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const LastItemComponent = ({
  lastItem: {
    id,
    product: { ean, parma_number, full_description },
    amount,
    total
  },
  updateLastItemAmount
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <ChangeAmountDialog
        amount={amount}
        open={open}
        onClose={() => setOpen(false)}
        onSave={newAmount => {
          updateLastItemAmount(id, newAmount);
          setOpen(false);
        }}
      />
      <Card elevation={3} square>
        <CardContent>
          <Typography variant="subtitle2">{full_description}</Typography>
          <Typography variant="body2">EAN: {ean}</Typography>
          <Typography variant="body2">Parma: {parma_number}</Typography>
          <Typography variant="body2">Anzahl: {amount}</Typography>
          <Typography variant="subtitle2">Gesamtanzahl: {total}</Typography>
          <Button
            variant="outlined"
            onClick={event => {
              event.currentTarget.blur();
              setOpen(true);
            }}
            className={styles.changeAmountButton}
          >
            Anzahl manuell ändern
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export const LastItem = connect(
  state => ({
    lastItem: getLastItem(state)
  }),
  {
    updateLastItemAmount
  }
)(LastItemComponent);
