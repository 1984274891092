import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${props => props.backgroundColor || props.theme.palette.common.lightWhite};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default class OverlayLoader extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    backgroundColor: PropTypes.string,
    loaderProps: PropTypes.object
  };

  render() {
    const { visible, backgroundColor, loaderProps } = this.props;

    if (!visible) {
      return null;
    }

    return (
      <LoaderContainer backgroundColor={backgroundColor}>
        <CircularProgress {...loaderProps} />
      </LoaderContainer>
    );
  }
}
