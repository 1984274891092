import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import { pushItems } from './api';
import scannedItemsRepository from './db/model/scanned-items';
import { operations as appOperations } from '../redux/modules/app';
import { MSG_ERROR_GONE } from '../config/constants';
import { getStore } from '../redux/store';

class LiveDataSync {
  defaultWaitTime = 60000;
  itemsToSync = new Set();
  pushTimer = throttle(debounce(this.syncItems), this.defaultWaitTime, {
    leading: true,
    trailing: true
  });
  errorCounter = 0;

  async initialize() {
    const items = await scannedItemsRepository
      .builder()
      .where('synced')
      .equals(0)
      .toArray();
    for (const item of items) {
      this.syncItem(item);
    }
  }

  syncItem(item) {
    this.itemsToSync.add(item.id);
    this.pushTimer();
  }

  async syncItems() {
    const itemIds = Array.from(this.itemsToSync);
    this.itemsToSync.clear();

    if (!itemIds.length) {
      return;
    }

    const itemsByInventory = new Map();
    for (const itemId of itemIds) {
      const item = await scannedItemsRepository.find(itemId);

      if (item.synced) {
        continue;
      }

      if (!itemsByInventory.has(item.inventory_id)) {
        itemsByInventory.set(item.inventory_id, new Set());
      }

      itemsByInventory.get(item.inventory_id).add(item);
    }

    for (const [inventoryId, inventoryItems] of itemsByInventory) {
      pushItems(inventoryId, inventoryItems)
        .then(() => {
          if (this.errorCounter > 0) {
            this.errorCounter = 0;
            this.resetTimer();
          }

          for (const item of inventoryItems) {
            scannedItemsRepository.find(item.id).then(item => {
              scannedItemsRepository.insert({
                ...item,
                synced: 1
              });
            });
          }
        })
        .catch(response => {
          if (response && response.status === 410) {
            getStore().dispatch(
              appOperations.errorUpload(MSG_ERROR_GONE, true)
            );
            return;
          }

          this.errorCounter++;
          this.resetTimer();

          for (const item of inventoryItems) {
            this.syncItem(item);
          }
        });
    }
  }

  resetTimer() {
    let leading = true;
    if (this.errorCounter > 0) {
      leading = false;
    }

    this.pushTimer.cancel();
    this.pushTimer = throttle(
      debounce(this.syncItems),
      this.defaultWaitTime + this.defaultWaitTime * this.errorCounter,
      { leading, trailing: true }
    );

    if (this.itemsToSync.size) {
      this.pushTimer();
    }
  }
}

const LiveDataSyncService = new LiveDataSync();
LiveDataSyncService.initialize();

export default LiveDataSyncService;
