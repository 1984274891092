import qs from 'qs';

import { BACKEND_API_URL } from '../../config/constants';

export const pushItem = item => {
  return new Promise((resolve, reject) => {
    fetch(BACKEND_API_URL + '/item.json', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        item,
        inventory_id: item.inventory_id
      })
    })
      .then(response => {
        if (response.ok) {
          resolve();
        } else {
          reject(response);
        }
      })
      .catch(error => reject(error));
  });
};

export const pushItems = (inventoryId, items) => {
  return new Promise((resolve, reject) => {
    fetch(BACKEND_API_URL + '/items.json', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        items,
        inventory_id: inventoryId
      })
    })
      .then(response => {
        if (response.ok) {
          resolve();
        } else {
          reject(response);
        }
      })
      .catch(error => reject(error));
  });
};

export const getProducts = cursor => {
  return fetch(BACKEND_API_URL + '/products.json?' + qs.stringify({ cursor }));
};

export const getInventories = () => {
  return new Promise((resolve, reject) => {
    fetch(BACKEND_API_URL + '/inventory.json')
      .then(response => {
        if (!response.ok) {
          reject('Invalid response');
        }

        return response.json();
      })
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getStock = inventory =>
  fetch(BACKEND_API_URL + `/inventory/${inventory.id}/stock.json`)
    .then(response => response.json())
    .then(data => data.products);

export const getItems = inventory =>
  fetch(BACKEND_API_URL + `/inventory/${inventory.id}/items.json`)
    .then(response => response.json())
    .then(data => data.scans);

export const getCorrections = inventory =>
  fetch(BACKEND_API_URL + `/inventory/${inventory.id}/corrections.json`)
    .then(response => response.json())
    .then(data => data.corrections);

export const getResults = inventory => {
  return new Promise((resolve, reject) => {
    fetch(BACKEND_API_URL + `/inventory/${inventory.id}/results.json`)
      .then(response => {
        if (!response.ok) {
          reject('Invalid response');
        }

        resolve(response.json());
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const uploadFinalData = (inventory, data) => {
  return new Promise((resolve, reject) => {
    fetch(BACKEND_API_URL + '/upload.json', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        inventory: inventory.id,
        data: data
      })
    })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const validateEmployeeAuth = number =>
  fetch(BACKEND_API_URL + '/employee/auth.json', {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ number })
  });
