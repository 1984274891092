import { createMuiTheme } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    common: {
      darkBlack: 'rgba(0, 0, 0, 0.87)',
      darkWhite: 'rgba(255, 255, 255, 0.87)',
      faintBlack: 'rgba(0, 0, 0, 0.12)',
      fullBlack: 'rgba(0, 0, 0, 1)',
      fullWhite: 'rgba(255, 255, 255, 1)',
      lightBlack: 'rgba(0, 0, 0, 0.54)',
      lightWhite: 'rgba(255, 255, 255, 0.54)',
      minBlack: 'rgba(0, 0, 0, 0.26)'
    },
    primary: {
      ...red
    },
    success: green,
    error: red
  },
  overrides: {
    MuiTableCell: {
      body: {
        height: 48,
        paddingTop: 4,
        paddingBottom: 4
      },
      head: {
        lineHeight: 1.43,
        height: 56,
        paddingTop: 4,
        paddingBottom: 4
      },
      sizeSmall: {
        height: 'auto'
      }
    }
  }
});

if (process.env.NODE_ENV !== 'production') {
  console.log(theme);
}

export default theme;
