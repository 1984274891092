import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, MenuItem, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import theme from 'config/theme';

const Container = styled.div`
  width: 25vh;
  min-width: 200px;
  height: 100vh;
`;

const Header = styled.div`
  height: 64px;
  background: ${theme.palette.primary[500]};
`;

const CloseButton = withStyles(theme => ({
  root: {
    marginTop: 8,
    marginLeft: 12,
    color: theme.palette.getContrastText(theme.palette.primary[500])
  }
}))(IconButton);

const MenuList = styled.ul`
  list-style: none;
  margin: 20px 0;
  padding: 0;
`;

export default class Menu extends Component {
  static propTypes = {
    onClose: PropTypes.func
  };

  render() {
    const { onClose } = this.props;

    return (
      <Container>
        <Header>
          <CloseButton onClick={onClose} tabIndex="-1">
            <CloseIcon />
          </CloseButton>
        </Header>
        <MenuList>
          <MenuItem component={Link} to="/" onClick={onClose}>
            Start
          </MenuItem>
          <MenuItem component={Link} to="/overview" onClick={onClose}>
            Artikelübersicht
          </MenuItem>
          <MenuItem component={Link} to="/results" onClick={onClose}>
            Übertragene Daten
          </MenuItem>
          {/* Commented out since it is not used for now, might be used later */}
          {/*<MenuItem component={Link} to="/stock" onClick={onClose}>*/}
          {/*  Differenzliste*/}
          {/*</MenuItem>*/}
        </MenuList>
      </Container>
    );
  }
}
