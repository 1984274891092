import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';

import * as types from './types';
import { ITEM_SCAN } from './constants';

const initialState = {
  fetching: false,
  lastItem: null
};

const fetching = (state = initialState.fetching, action) => {
  switch (action.type) {
    case types.ADDING_ITEM:
      return true;

    case types.ADD_ITEM:
      return false;

    default:
      return state;
  }
};

const lastItem = (state = initialState.lastItem, action) => {
  switch (action.type) {
    case types.ADD_ITEM: {
      if (action.payload.item.type === ITEM_SCAN) {
        return {
          ...action.payload.item,
          total: action.payload.total
        };
      }

      return state;
    }

    case types.UPDATE_LAST_ITEM_AMOUNT: {
      if (action.payload.itemId === state.id) {
        return {
          ...state,
          amount: action.payload.amount,
          total: action.payload.total
        };
      }

      return state;
    }

    case types.CLEAR_ITEMS: {
      return null;
    }

    default:
      return state;
  }
};

const inventoryReducer = combineReducers({
  fetching,
  lastItem
});

export default persistReducer({ key: 'inventory', storage, whitelist: ['lastItem'] }, inventoryReducer);
