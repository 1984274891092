import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton, Input, InputAdornment } from '@material-ui/core';
import KeyboardReturn from '@material-ui/icons/KeyboardReturn';

import { operations, selectors } from 'redux/modules/inventory';
import { LastItem } from './lastItem';

import styles from './styles.module.css';

export class Scanner extends Component {
  static propTypes = {
    fetching: PropTypes.bool,
    lastItem: PropTypes.object,
    onScan: PropTypes.func.isRequired
  };

  state = {
    code: '',
    value: ''
  };

  constructor(props) {
    super(props);

    this.handleKeydown = this.handleKeydown.bind(this);
  }

  handleKeydown = e => {
    const { code, value } = this.state;

    if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 13) {
      return;
    }

    if (e.keyCode === 13) {
      if (!code || value !== '') {
        if (value.length >= 8) {
          this.onSubmit(value);
        }

        return;
      }

      this.onSubmit(code);
      return;
    }

    // we cant use e.key on old devices, so get number by key code
    const number = e.keyCode - 48;

    this.setState({ code: code + number });
  };

  componentDidMount = () => {
    document.addEventListener('keydown', this.handleKeydown);
  };

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.handleKeydown);
  };

  onChange = event => {
    this.setState({
      value: event.target.value
    });
  };

  onSubmit = code => {
    const { onScan } = this.props;
    if (code && code.length >= 8) {
      onScan && onScan(code);
    }

    this.setState({
      code: '',
      value: ''
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.onSubmit(this.state.value);
  };

  render() {
    const { lastItem } = this.props;
    const { value } = this.state;

    let lastItemElement = null;
    if (lastItem) {
      lastItemElement = (
        <div className={styles.lastItem}>
          <h3>Letzter Scan:</h3>
          <LastItem />
        </div>
      );
    }

    return (
      <div className={styles.scanner}>
        <div className={styles.inputContainer}>
          <form onSubmit={this.handleSubmit}>
            <Input
              type="text"
              className={styles.searchInput}
              value={value}
              onChange={this.onChange}
              autoFocus={true}
              onFocus={event => {
                const tmp = event.target.value;
                event.target.value = '';
                event.target.value = tmp;
                return true;
              }}
              placeholder="Parma oder EAN hier eintragen..."
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="Eingeben" color="primary" onClick={this.handleSubmit}>
                    <KeyboardReturn />
                  </IconButton>
                </InputAdornment>
              }
            />
          </form>
        </div>
        <h1 className={styles.scanInfo}>Bitte scannen...</h1>

        {lastItemElement}
      </div>
    );
  }
}

const mapState = state => ({
  fetching: selectors.getFetching(state),
  lastItem: selectors.getLastItem(state)
});

const mapDispatch = dispatch => ({
  onScan: code => {
    dispatch(operations.scanItem(code));
  }
});

export default connect(mapState, mapDispatch)(Scanner);
