import * as types from './types';

export const manualChangeNext = values => {
  return {
    type: types.MANUAL_CHANGE_NEXT,
    payload: {
      values: values
    }
  };
};

export const manualClearNext = () => {
  return {
    type: types.MANUAL_CLEAR_NEXT
  };
};

export default {
  manualChangeNext,
  manualClearNext
};
