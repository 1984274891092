export const getModule = state => state.stock;

export const getFetching = state => getModule(state).fetching;
export const getData = state => getModule(state).data;
export const getPage = state => getModule(state).page;
export const getSort = state => getModule(state).sort;
export const getFilter = state => getModule(state).filter;
export const getFilterSearch = state => getFilter(state).search;
export const getFilterProperty = state => getFilter(state).property;
export const getFilteredCount = state => getModule(state).filteredCount;
export const getTotalCount = state => getModule(state).totalCount;
export const getLength = state => getModule(state).length;
export const getStatistics = state => getModule(state).statistics;

export default {
  getModule,
  getFetching,
  getData,
  getPage,
  getSort,
  getFilter,
  getFilterSearch,
  getFilterProperty,
  getFilteredCount,
  getTotalCount,
  getLength
};
