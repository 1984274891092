import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';

import * as types from './types';
import { combineReducers } from 'redux';

const initialState = {
  started: false,
  swUpdateAvailable: false,
  inventory: null,
  isStockListSeen: true,
  employee: {
    fetching: false,
    error: null,
    number: null
  },
  data: {
    products: {
      fetching: false,
      offline: false,
      error: null,
      cursor: null,
      lastCursor: null,
      lastUpdated: null
    },
    inventories: {
      fetching: false,
      error: false,
      lastUpdated: null,
      items: []
    },
    stock: {
      fetching: false,
      error: false,
      lastUpdated: null
    },
    items: {
      fetching: false,
      error: false,
      lastUpdated: null
    },
    corrections: {
      fetching: false,
      error: false,
      lastUpdated: null
    }
  },
  upload: {
    fetching: false,
    error: null,
    confirmable: false
  },
  alert: {
    show: false,
    title: null,
    message: null
  }
};

const started = (state = initialState.started, action) => {
  switch (action.type) {
    case types.START:
      return true;

    case types.FINISH:
      return false;

    default:
      return state;
  }
};

const inventory = (state = initialState.inventory, action) => {
  switch (action.type) {
    case types.START:
      return action.payload.inventory;

    case types.FINISH:
      return null;

    default:
      return state;
  }
};

const swUpdateAvailable = (state = initialState.swUpdateAvailable, action) => {
  switch (action.type) {
    case types.SW_UPDATE_AVAILABLE:
      return true;

    default:
      return state;
  }
};

const isStockListSeen = (state = initialState.isStockListSeen, action) => {
  switch (action.type) {
    // Commented out since stock list is not used for now
    // case types.START:
    // case types.FINISH:
    //   return false;

    case types.CONFIRM_STOCK_LIST_SEEN:
      return true;

    default:
      return state;
  }
};

const employee = (state = initialState.employee, action) => {
  switch (action.type) {
    case types.EMPLOYEE_AUTH_START:
      return { ...state, fetching: true, error: null };
    case types.EMPLOYEE_AUTH_ERROR:
      return { ...state, fetching: false, error: action.payload.error };
    case types.EMPLOYEE_AUTH_FINISH:
      return { ...state, fetching: false, number: action.payload.number };
    case types.START:
    case types.FINISH:
      return { ...initialState.employee };
    default:
      return state;
  }
};

const employeeReducer = persistReducer(
  {
    key: 'app.employee',
    storage,
    whitelist: ['number']
  },
  employee
);

const products = (state = initialState.data.products, action) => {
  switch (action.type) {
    case types.FETCH_PRODUCTS: {
      return {
        ...state,
        fetching: true
      };
    }

    case types.FETCH_PRODUCTS_OFFLINE: {
      return {
        ...state,
        offline: true
      };
    }

    case types.FETCH_PRODUCTS_ERROR: {
      return {
        ...state,
        fetching: false,
        error: {
          message: action.payload.message
        }
      };
    }

    case types.RECEIVE_PRODUCTS: {
      return {
        ...state,
        offline: false,
        error: null,
        cursor: action.payload.cursor,
        lastCursor: action.payload.lastCursor || state.lastCursor
      };
    }

    case types.UPDATE_PRODUCTS_FINISHED: {
      return {
        ...state,
        fetching: false,
        offline: false,
        error: null,
        cursor: null,
        lastCursor: null,
        lastUpdated: new Date().toISOString()
      };
    }

    case types.CLEAR_PRODUCTS: {
      return {
        ...state,
        lastUpdated: null
      };
    }

    default:
      return state;
  }
};

const productsReducer = persistReducer(
  {
    key: 'app.data.products',
    storage,
    whitelist: ['cursor', 'lastCursor', 'lastUpdated']
  },
  products
);

const inventories = (state = initialState.data.inventories, action) => {
  switch (action.type) {
    case types.FETCH_INVENTORIES: {
      return {
        ...state,
        fetching: true,
        error: false
      };
    }

    case types.FETCH_INVENTORIES_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true
      };
    }

    case types.RECEIVE_INVENTORIES: {
      return {
        ...state,
        fetching: false,
        lastUpdated: new Date().toISOString(),
        items: action.payload.inventories
      };
    }

    case types.CLEAR_INVENTORIES: {
      return {
        ...state,
        lastUpdated: null,
        items: []
      };
    }

    default:
      return state;
  }
};

const inventoriesReducer = persistReducer(
  { key: 'app.data.inventories', storage, whitelist: ['lastUpdated', 'items'] },
  inventories
);

const stock = (state = initialState.data.stock, action) => {
  switch (action.type) {
    case types.FETCH_STOCK_START: {
      return {
        ...state,
        fetching: true,
        error: false
      };
    }

    case types.FETCH_STOCK_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true
      };
    }

    case types.FETCH_STOCK_FINISH: {
      return {
        ...state,
        fetching: false,
        lastUpdated: new Date().toISOString()
      };
    }

    case types.CLEAR_STOCK:
      return initialState.data.stock;

    default:
      return state;
  }
};

const stockReducer = persistReducer({ key: 'app.data.stock', storage, whitelist: ['lastUpdated'] }, stock);

const items = (state = initialState.data.items, action) => {
  switch (action.type) {
    case types.FETCH_ITEMS_START: {
      return {
        ...state,
        fetching: true,
        error: false
      };
    }

    case types.FETCH_ITEMS_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true
      };
    }

    case types.FETCH_ITEMS_FINISH: {
      return {
        ...state,
        fetching: false,
        lastUpdated: new Date().toISOString()
      };
    }

    case types.CLEAR_ITEMS:
      return initialState.data.items;

    default:
      return state;
  }
};

const itemsReducer = persistReducer({ key: 'app.data.items', storage, whitelist: ['lastUpdated'] }, items);

const corrections = (state = initialState.data.corrections, action) => {
  switch (action.type) {
    case types.FETCH_CORRECTIONS_START: {
      return {
        ...state,
        fetching: true,
        error: false
      };
    }

    case types.FETCH_CORRECTIONS_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true
      };
    }

    case types.FETCH_CORRECTIONS_FINISH: {
      return {
        ...state,
        fetching: false,
        lastUpdated: new Date().toISOString()
      };
    }

    case types.CLEAR_CORRECTIONS:
      return initialState.data.corrections;

    default:
      return state;
  }
};

const correctionsReducer = persistReducer(
  { key: 'app.data.corrections', storage, whitelist: ['lastUpdated'] },
  corrections
);

const dataReducer = (state = initialState.data, action) => ({
  ...state,
  products: productsReducer(state.products, action),
  inventories: inventoriesReducer(state.inventories, action),
  stock: stockReducer(state.stock, action),
  items: itemsReducer(state.items, action),
  corrections: correctionsReducer(state.corrections, action)
});

const uploadReducer = (state = initialState.upload, action) => {
  switch (action.type) {
    case types.INIT: {
      return { ...initialState.upload };
    }

    case types.FETCH_UPLOAD: {
      return {
        ...state,
        fetching: true,
        error: null,
        confirmable: false
      };
    }

    case types.RECEIVE_UPLOAD: {
      return {
        ...state,
        fetching: false,
        error: null
      };
    }

    case types.ERROR_UPLOAD: {
      return {
        ...state,
        fetching: false,
        error: action.payload.message,
        confirmable: action.payload.confirmable
      };
    }

    case types.DISMISS_UPLOAD_ERROR: {
      return {
        ...state,
        error: null
      };
    }

    default:
      return state;
  }
};

const alertReducer = (state = initialState.alert, action) => {
  switch (action.type) {
    case types.DISPLAY_ALERT: {
      return {
        ...state,
        show: true,
        title: action.payload.title,
        message: action.payload.message
      };
    }

    case types.CONFIRM_ALERT: {
      return {
        ...state,
        show: false
      };
    }

    default:
      return state;
  }
};

const appReducer = combineReducers({
  started,
  inventory,
  swUpdateAvailable,
  isStockListSeen,
  employee: employeeReducer,
  data: dataReducer,
  upload: uploadReducer,
  alert: alertReducer
});

export default persistReducer({ key: 'app', storage, whitelist: ['started', 'inventory'] }, appReducer);
