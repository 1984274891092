const EuroFormatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });

export const config = {
  columns: [
    {
      label: 'Parma Nummer',
      property: 'parma_number',
      cellProps: {
        style: {
          fontSize: '0.75rem',
          paddingRight: 4,
          paddingLeft: 4
        }
      }
    },
    {
      label: 'EAN',
      property: 'ean',
      cellProps: {
        style: {
          fontSize: '0.75rem',
          paddingRight: 4,
          paddingLeft: 4
        }
      }
    },
    {
      label: 'Marke',
      property: 'brand_description',
      cellProps: {
        style: {
          fontSize: '0.75rem',
          paddingRight: 4,
          paddingLeft: 4
        }
      }
    },
    {
      label: 'Beschreibung',
      property: 'full_description',
      cellProps: {
        style: {
          fontSize: '0.75rem',
          paddingRight: 4,
          paddingLeft: 4
        }
      }
    },
    {
      label: 'VK',
      property: 'price',
      cellProps: {
        align: 'right',
        style: {
          fontSize: '0.75rem',
          paddingRight: 4,
          paddingLeft: 4
        }
      },
      render: value => EuroFormatter.format(value)
    },
    {
      label: 'EK',
      property: 'purchase_price',
      cellProps: {
        align: 'right',
        style: {
          fontSize: '0.75rem',
          paddingRight: 4,
          paddingLeft: 4
        }
      },
      render: value => EuroFormatter.format(value)
    },
    {
      label: 'Bestand',
      property: 'stock_qty',
      cellProps: {
        align: 'right',
        style: {
          fontSize: '0.75rem',
          paddingRight: 4,
          paddingLeft: 4
        }
      }
    },
    {
      label: 'Anzahl Inventur',
      property: 'scan_qty',
      cellProps: {
        align: 'right',
        style: {
          fontSize: '0.75rem',
          paddingRight: 4,
          paddingLeft: 4
        }
      }
    },
    {
      label: 'Diff. Bestand',
      property: 'diff_qty',
      cellProps: {
        align: 'right',
        style: {
          fontSize: '0.75rem',
          paddingRight: 4,
          paddingLeft: 4
        }
      }
    },
    {
      label: 'Diff. EK',
      property: 'diff_purchase_price',
      cellProps: {
        align: 'right',
        style: {
          fontSize: '0.75rem',
          paddingRight: 4,
          paddingLeft: 4
        }
      },
      render: value => EuroFormatter.format(value)
    },
    {
      label: 'Manuelle Filialeingabe',
      property: 'corrected_qty',
      cellProps: {
        align: 'right',
        style: {
          fontSize: '0.75rem',
          padding: '0 8px'
        }
      }
    }
  ]
};
