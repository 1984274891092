import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import { operations as inventoryOperations } from 'redux/modules/inventory';
import ItemsTable from 'modules/manual-count/items-table';
import NewItem from './new-item';

import styles from './styles.module.css';

export class ManualCount extends Component {
  static propTypes = {
    onRemove: PropTypes.func.isRequired
  };

  state = {
    confirmRemoveItem: null
  };

  handleRemoveClick = item => {
    this.setState({ confirmRemoveItem: item });
  };

  handleClose = () => {
    this.setState({ confirmRemoveItem: null });
  };

  handleRemove = () => {
    const item = this.state.confirmRemoveItem;

    this.setState({ confirmRemoveItem: null });

    this.props.onRemove(item);
  };

  render() {
    const { confirmRemoveItem } = this.state;

    return (
      <div className={styles.root}>
        <Grid container spacing={2} className={styles.container}>
          <Grid item xs={12} md={5}>
            <Paper elevation={3} square className={styles.newItem}>
              <Typography variant="h6" gutterBottom>
                Artikel hinzufügen
              </Typography>
              <NewItem />
            </Paper>
          </Grid>
          <Grid item xs={12} md={7}>
            <ItemsTable onRemoveItem={this.handleRemoveClick} />
          </Grid>
        </Grid>
        <Dialog open={!!confirmRemoveItem} onClose={this.handleClose}>
          <DialogTitle>Artikel löschen?</DialogTitle>
          <DialogContent>
            <DialogContentText>Der Eintrag wird gelöscht.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Abbrechen
            </Button>
            <Button onClick={this.handleRemove} color="primary">
              Löschen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRemove: item => {
      dispatch(inventoryOperations.removeItem(item));
    }
  };
};

export default connect(null, mapDispatchToProps)(ManualCount);
