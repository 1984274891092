import actions from './actions';
import selectors from './selectors';
import resultsRepository from 'app/db/model/results';
import { getResults } from 'app/api';
import { getActiveInventory } from 'redux/modules/app/selectors';
import { config as tableConfig } from '../../../modules/products-overview/table/config';

export const syncResults = () => async (dispatch, getState) => {
  const inventory = getActiveInventory(getState());

  if (!inventory) return;

  try {
    const results = await getResults(inventory);

    resultsRepository.insertBulk(results);
  } catch (e) {
    console.error(e);
  }
};

export const fetch = () => async (dispatch, getState) => {
  const { columns } = tableConfig;
  const state = getState();
  const inventory = getActiveInventory(state);

  dispatch(actions.fetch());

  const page = selectors.getPage(state);
  const length = selectors.getLength(state);
  const order = selectors.getSort(state);

  const totalCount = await resultsRepository
    .builder()
    .where('inventory_id')
    .equals(inventory.id)
    .count();

  const builder = resultsRepository
    .builder()
    .where('inventory_id')
    .equals(inventory.id);

  builder.filter(item => !item.deleted);

  let rows;

  if (order.length) {
    if (order[0].dir === 'desc') {
      builder.reverse();
    }

    rows = await builder.sortBy(columns[order[0].column].property);
  } else {
    rows = await builder.toArray();
  }

  const filteredCount = rows.length;

  const data = {
    recordsTotal: totalCount,
    recordsFiltered: filteredCount,
    data: rows.slice(page * length, (page + 1) * length)
  };

  dispatch(actions.receive(data));
};

export const changePage = page => dispatch => {
  dispatch(actions.changePage(page));
  dispatch(fetch());
};

export const changeSort = index => dispatch => {
  dispatch(actions.changeSort(index));
  dispatch(fetch());
};

export const changeFilter = (search, property) => (dispatch, getState) => {
  const prevSearch = selectors.getFilterSearch(getState());

  dispatch(actions.changeFilter(search, property));

  if (!search && search === prevSearch) {
    return;
  }

  dispatch(fetch());
};

export default {
  syncResults,
  fetch,
  changePage,
  changeSort,
  changeFilter
};
