import actions from './actions';
import { operations as inventoryOperations } from 'redux/modules/inventory';
import { operations as itemsOperations } from 'redux/modules/manual-count/items';

export const manualChangeNext = actions.manualChangeNext;
export const manualClearNext = actions.manualClearNext;
export const manualSubmit = item => dispatch => {
  dispatch(inventoryOperations.addManualItem(item)).then(() => {
    dispatch(itemsOperations.fetch());
  });

  dispatch(manualClearNext());
};

export default {
  manualChangeNext,
  manualClearNext,
  manualSubmit
};
