import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import DefaultTextField from '@material-ui/core/TextField';

export default class TextField extends Component {
  input = null;

  onKeyPress = event => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      if (this.props.onSubmit) {
        this.props.onSubmit(ReactDOM.findDOMNode(this.input).value, event);
      } else if (this.props.onKeyPress) {
        this.props.onSubmit(ReactDOM.findDOMNode(this.input).value, event);
      }
    }
  };

  _refInput = ref => {
    if (this.props.inputRef) this.props.inputRef(ref);
    this.input = ref;
  };

  componentDidMount() {
    ReactDOM.findDOMNode(this.input).addEventListener('keypress', this.onKeyPress);
  }

  componentWillUnmount() {
    ReactDOM.findDOMNode(this.input).removeEventListener('keypress', this.onKeyPress);
  }

  render() {
    const props = { ...this.props, inputRef: this._refInput };
    delete props.onSubmit;

    return <DefaultTextField {...props} />;
  }
}
