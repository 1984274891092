import React, { Component } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { Button, CircularProgress } from '@material-ui/core';

import StockTable from './table';
import { connect } from 'react-redux';
import {
  isInventoryDataError,
  isInventoryDataFetching,
  isInventoryDataUpdated
} from '../../redux/modules/app/selectors';
import { loadCurrentInventoryData } from '../../redux/modules/app/operations';
import Statistics from './statistics';

const Container = styled.div`
  padding: 8px;
`;

const Title = styled(Typography)`
  margin-top: 8px !important;
  margin-bottom: 24px !important;
`;

const MessageContainer = styled.div`
  text-align: center;
`;

const UpdateButtonContainer = styled.div`
  text-align: center;
  margin-top: 16px;
`;

class StockOverviewComponent extends Component {
  render() {
    const { fetching, error, updated, onUpdateRequested } = this.props;

    return (
      <Container>
        <Title variant="h6">Differenzliste</Title>
        {fetching && (
          <MessageContainer>
            <div>
              <CircularProgress />
            </div>
            Bestandsdaten werden geladen. Bitte warten...
          </MessageContainer>
        )}
        {!fetching && error && (
          <>
            <MessageContainer>Beim Laden der Bestandsdaten ist ein Fehler aufgetreten.</MessageContainer>
            <UpdateButtonContainer>
              <Button variant="contained" color="primary" onClick={onUpdateRequested}>
                Jetzt aktualisieren
              </Button>
            </UpdateButtonContainer>
          </>
        )}
        {!fetching && !error && !updated && (
          <>
            <MessageContainer>Die Bestandsdaten sind nicht aktuell, bitte aktualisieren.</MessageContainer>
            <UpdateButtonContainer>
              <Button variant="contained" color="primary" onClick={onUpdateRequested}>
                Jetzt aktualisieren
              </Button>
            </UpdateButtonContainer>
          </>
        )}
        {!fetching && !error && updated && (
          <>
            <StockTable />
            <Statistics />
          </>
        )}
      </Container>
    );
  }
}

const StockOverview = connect(
  state => ({
    fetching: isInventoryDataFetching(state),
    error: isInventoryDataError(state),
    updated: isInventoryDataUpdated(state)
  }),
  {
    onUpdateRequested: loadCurrentInventoryData
  }
)(StockOverviewComponent);

export default StockOverview;
