import React from 'react';
import { CircularProgress } from '@material-ui/core';

import styles from './styles.module.css';

const InitLoader = () => {
  return (
    <div className={styles.container}>
      <div className={styles.progress}>
        <CircularProgress />
      </div>
    </div>
  );
};

export default InitLoader;
