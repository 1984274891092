export const FETCH = 'manual-count/items/FETCH';
export const RECEIVE = 'manual-count/items/RECEIVE';
export const CHANGE_PAGE = 'manual-count/items/CHANGE_PAGE';
export const CHANGE_SORT = 'manual-count/items/CHANGE_SORT';
export const CHANGE_FILTER = 'manual-count/items/CHANGE_FILTER';

export default {
  FETCH,
  RECEIVE,
  CHANGE_PAGE,
  CHANGE_SORT,
  CHANGE_FILTER
};
