import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { TextField } from '@material-ui/core';

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.palette.common.lightWhite};
`;

const StyledDialogTitle = styled(DialogTitle)`
  text-align: center;
`;

const StyledContent = styled(DialogContent)`
  align-self: center;
  text-align: center;
`;

const ErrorMessage = styled(Typography)`
  color: ${props => props.theme.palette.error.main} !important;
  margin-bottom: 20px !important;
`;

const InputField = styled(TextField)`
  width: 180px;
`;

const FormContainer = styled.form`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
`;

export default class EmployeePrompt extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    fetching: PropTypes.bool,
    error: PropTypes.any,
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    hintMessage: PropTypes.string.isRequired,
    validator: PropTypes.func,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func.isRequired
  };

  state = {
    value: '',
    error: null
  };

  constructor(props) {
    super(props);

    this.textInputRef = React.createRef();
  }

  componentDidUpdate() {
    const { fetching } = this.props;

    if (!fetching && this.textInputRef.current) {
      this.textInputRef.current.focus();
    }
  }

  validateValue = value => {
    const { validator } = this.props;

    const error = validator && !validator(value);

    this.setState({ error: error });

    return !error;
  };

  handleChange = event => {
    const value = event.target.value;

    this.setState({ value: value });

    this.validateValue(value);
  };

  handleSubmit = event => {
    event.preventDefault();

    const { value } = this.state;

    if (!this.validateValue(value)) {
      return;
    }

    if (!value.trim()) {
      this.setState({ error: true });
      return;
    }

    this.props.onSubmit(value.trim());
  };

  render() {
    const { open, fetching, error, title, label, hintMessage, onClose } = this.props;
    const { value } = this.state;

    return (
      <Dialog onClose={onClose} open={open}>
        <FormContainer onSubmit={this.handleSubmit}>
          <StyledDialogTitle>{title}</StyledDialogTitle>
          <StyledContent>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <InputField
              type="number"
              label={label}
              autoFocus={true}
              value={value}
              onChange={this.handleChange}
              error={this.state.error}
              helperText={this.state.error ? hintMessage : null}
              inputProps={{
                min: 0
              }}
              inputRef={this.textInputRef}
            />
          </StyledContent>
          <DialogActions>
            <Button color="default" disabled={fetching} onClick={onClose}>
              Abbrechen
            </Button>
            <Button variant="contained" color="primary" disabled={fetching} type="submit">
              Bestätigen
            </Button>
          </DialogActions>
          {fetching && (
            <LoaderContainer>
              <CircularProgress />
            </LoaderContainer>
          )}
        </FormContainer>
      </Dialog>
    );
  }
}
