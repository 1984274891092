import React, { useEffect } from 'react';
import { Paper, Table, TableBody, TableHead, TableCell, TableRow, Typography, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { fetchStatistics } from '../../redux/modules/stock/operations';
import { getStatistics } from '../../redux/modules/stock/selectors';
import OverlayLoader from '../util/overlay-loader';

const EuroFormatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(2)
  },
  header: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  }
}));

const StatisticsComponent = ({ fetching, error, data, fetch }) => {
  const classes = useStyles();

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Paper className={classes.root}>
      <Typography variant="h6" className={classes.header}>
        Gesamtmengen (gefiltert)
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Bestand</TableCell>
            <TableCell>VK</TableCell>
            <TableCell>EK</TableCell>
            <TableCell>Differenz Bestand</TableCell>
            <TableCell>Differenz EK</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{data ? data.stock : 0}</TableCell>
            <TableCell>{EuroFormatter.format(data ? data.price : 0)}</TableCell>
            <TableCell>{EuroFormatter.format(data ? data.purchase_price : 0)}</TableCell>
            <TableCell>{data ? data.stock_diff : 0}</TableCell>
            <TableCell>{EuroFormatter.format(data ? data.purchase_price_diff : 0)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <OverlayLoader visible={fetching} loaderProps={{ size: 32 }} />
    </Paper>
  );
};

const Statistics = connect(
  state => ({
    fetching: getStatistics(state).fetching,
    error: getStatistics(state).error,
    data: getStatistics(state).data
  }),
  {
    fetch: fetchStatistics
  }
)(StatisticsComponent);

export default Statistics;
