import * as types from './types';

export const addingItem = code => ({
  type: types.ADDING_ITEM,
  payload: { code }
});

export const addItem = (item = {}, total = 1) => ({
  type: types.ADD_ITEM,
  payload: { item, total }
});

export const removeItem = item => {
  return {
    type: types.REMOVE_ITEM,
    payload: {
      item: item
    }
  };
};

export const clearItems = () => {
  return {
    type: types.CLEAR_ITEMS
  };
};

export const updateLastItemAmount = (itemId, amount, total) => ({
  type: types.UPDATE_LAST_ITEM_AMOUNT,
  payload: {
    itemId,
    amount,
    total
  }
});

export default {
  addingItem,
  addItem,
  removeItem,
  clearItems,
  updateLastItemAmount
};
