import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography
} from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import { padStart } from 'lodash';

import styles from './styles.module.css';

export default class Inventories extends Component {
  static propTypes = {
    fetching: PropTypes.bool.isRequired,
    inventories: PropTypes.array.isRequired,
    selectedInventory: PropTypes.object,
    onChange: PropTypes.func.isRequired
  };

  handleInventoryClick = inventory => {
    const { onChange } = this.props;

    onChange(inventory);
  };

  render() {
    const { fetching, inventories, selectedInventory } = this.props;

    if (fetching) {
      return (
        <List className={styles.loader}>
          <CircularProgress />
          <Typography align="center" variant="subtitle2">
            Wird aktualisiert.
          </Typography>
        </List>
      );
    }

    if (!inventories.length) {
      return (
        <List>
          <ListItem className={styles.emptyMessage}>
            <ListItemText primary="Keine Inventuren geplant." />
          </ListItem>
        </List>
      );
    }

    return (
      <List>
        {inventories.map(inventory => (
          <ListItem button key={inventory.id} onClick={() => this.handleInventoryClick(inventory)}>
            <ListItemAvatar>
              <Avatar>
                <FolderIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              className={styles.itemText}
              primary={inventory.name}
              secondary={'Filiale ' + padStart(inventory.store.id, 3, '0') + ' ' + inventory.store.name}
            />
            <ListItemSecondaryAction>
              <Checkbox
                checked={selectedInventory === inventory}
                onClick={() => this.handleInventoryClick(inventory)}
                color="primary"
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  }
}
