import * as types from './types';

export const fetch = () => ({
  type: types.FETCH
});

export const receive = data => ({
  type: types.RECEIVE,
  payload: {
    data: data
  }
});

export const changePage = page => ({
  type: types.CHANGE_PAGE,
  payload: {
    page: page
  }
});

export const changeSort = index => ({
  type: types.CHANGE_SORT,
  payload: {
    index: index
  }
});

export const changeFilter = (search, property) => ({
  type: types.CHANGE_FILTER,
  payload: {
    search: search,
    property: property
  }
});

export default {
  fetch,
  receive,
  changePage,
  changeSort,
  changeFilter
};
