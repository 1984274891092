import * as types from './types';

export const fetch = () => ({
  type: types.FETCH
});

export const receive = data => ({
  type: types.RECEIVE,
  payload: {
    data: data
  }
});

export const changePage = page => ({
  type: types.CHANGE_PAGE,
  payload: {
    page: page
  }
});

export const changePageLength = length => ({
  type: types.CHANGE_PAGE_LENGTH,
  payload: { length }
});

export const changeSort = index => ({
  type: types.CHANGE_SORT,
  payload: {
    index: index
  }
});

export const changeFilter = (search, property) => ({
  type: types.CHANGE_FILTER,
  payload: {
    search: search,
    property: property
  }
});

export const changeColumnData = (idField, idValue, changeField, changedValue) => ({
  type: types.CHANGE_COLUMN_DATA,
  payload: { idField, idValue, changeField, changedValue }
});

export const fetchStatisticsStart = () => ({ type: types.FETCH_STATISTICS_START });
export const fetchStatisticsError = error => ({ type: types.FETCH_STATISTICS_ERROR, payload: { error } });
export const fetchStatisticsFinish = data => ({ type: types.FETCH_STATISTICS_FINISH, payload: { data } });

export default {
  fetch,
  receive,
  changePage,
  changePageLength,
  changeSort,
  changeFilter,
  changeColumnData,
  fetchStatisticsStart,
  fetchStatisticsError,
  fetchStatisticsFinish
};
