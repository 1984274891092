import db from '../db';

class Stock {
  builder() {
    return db.stock;
  }

  insertBulk(rows) {
    return this.builder().bulkPut(rows);
  }

  clear() {
    return this.builder().clear();
  }

  findByProductId(id) {
    return this.builder()
      .where('product_id')
      .equals(id)
      .first();
  }
}

const stockRepository = new Stock();

export default stockRepository;
