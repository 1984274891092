import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Filter from 'modules/util/datatable/filter';
import { selectors, operations } from 'redux/modules/stock';

export class StockFilter extends Component {
  static propTypes = {
    search: PropTypes.string,
    property: PropTypes.string
  };

  handleChangeFilter = (search, property) => {
    const { changeFilter } = this.props;

    if (property === 'parma_number' && !isNaN(search)) {
      if (property.length >= 8) {
        search = search.substring(0, 8);
      }
    }

    changeFilter && changeFilter(search, property);
  };

  render() {
    const { search, property } = this.props;

    return (
      <Filter
        options={[
          { label: 'Alle', value: '' },
          { label: 'Parma Nummer', value: 'parma_number' },
          { label: 'EAN', value: 'ean' },
          { label: 'Marke', value: 'brand_description' },
          { label: 'Beschreibung', value: 'full_description' }
        ]}
        onChange={this.handleChangeFilter}
        search={search}
        property={property}
      />
    );
  }
}

const mapState = state => ({
  search: selectors.getFilterSearch(state),
  property: selectors.getFilterProperty(state)
});

const mapDispatch = dispatch => ({
  changeFilter: (search, property) => dispatch(operations.changeFilter(search, property))
});

export default connect(mapState, mapDispatch)(StockFilter);
