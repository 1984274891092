import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { operations, selectors } from 'redux/modules/stock';
import DataTable from 'modules/util/datatable/paginated-datatable';
import Filter from './filter';
import { createStyles, makeStyles, TextField, withStyles } from '@material-ui/core';
import { getEmployee } from '../../../redux/modules/app/selectors';
import EmployeePrompt from '../../app/employee-prompt';
import { authEmployee } from '../../../redux/modules/app/operations';
import { config } from './config';

const InputStyles = makeStyles(theme =>
  createStyles({
    root: {
      fontSize: '0.875rem'
    },
    input: {
      padding: 4,
      textAlign: 'center'
    }
  })
);

const ManualInputField = withStyles(theme => ({
  root: {
    width: 64,
    marginTop: 0,
    marginBottom: 0
  }
}))(props => (
  <TextField
    variant="outlined"
    margin="dense"
    type="number"
    InputProps={{
      classes: InputStyles()
    }}
    {...props}
  />
));

export class StockTable extends Component {
  static propTypes = {
    fetching: PropTypes.bool,
    page: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
    filteredCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    data: PropTypes.array,
    fetchData: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    changePageLength: PropTypes.func.isRequired,
    changeSort: PropTypes.func.isRequired
  };

  state = {
    employeeDialogOpen: false
  };

  static getDerivedStateFromProps(props, state) {
    if (props.employee.number) {
      return { employeeDialogOpen: false };
    }

    return null;
  }

  componentDidMount() {
    this.props.fetchData();
  }

  render() {
    const {
      fetching,
      data,
      page,
      length,
      sort,
      filteredCount,
      totalCount,
      changePage,
      changePageLength,
      changeSort,
      changeCorrection,
      employee,
      authEmployee
    } = this.props;
    const { employeeDialogOpen } = this.state;

    const columns = config.columns;
    columns[columns.length - 1].render = (value, row) => {
      return (
        <ManualInputField
          inputProps={{ min: 0 }}
          value={value || ''}
          onChange={event => changeCorrection(row.product_id, parseInt(event.target.value, 10))}
          disabled={!employee.number}
          onClick={event => {
            if (!employee.number) {
              this.setState({ employeeDialogOpen: true });
              event.preventDefault();
              return false;
            }
          }}
        />
      );
    };

    return (
      <div>
        <Filter />
        <DataTable
          fetching={fetching}
          data={data}
          page={page}
          length={length}
          lengthOptions={[10, 25, 50, 100]}
          sort={sort}
          filteredCount={filteredCount}
          totalCount={totalCount}
          onPageChange={changePage}
          onPageLengthChange={changePageLength}
          onSort={changeSort}
          columns={columns}
        />
        <EmployeePrompt
          open={employeeDialogOpen}
          onClose={() => this.setState({ employeeDialogOpen: false })}
          fetching={employee.fetching}
          error={employee.error}
          title="Bitte Mitarbeiternummer eingeben:"
          label="Mitarbeiternummer"
          hintMessage="Bitte eine gültige Mitarbeiternummer eingeben."
          validator={value => !!value && value.trim().match(/^[0-9]{1,4}$/)}
          onSubmit={value => authEmployee(value)}
        />
      </div>
    );
  }
}

const mapState = state => ({
  fetching: selectors.getFetching(state),
  page: selectors.getPage(state),
  length: selectors.getLength(state),
  filteredCount: selectors.getFilteredCount(state),
  totalCount: selectors.getTotalCount(state),
  data: selectors.getData(state),
  sort: selectors.getSort(state),
  employee: getEmployee(state)
});

const mapDispatch = dispatch => ({
  fetchData: () => dispatch(operations.fetch()),
  changePage: page => dispatch(operations.changePage(page)),
  changePageLength: length => dispatch(operations.changePageLength(length)),
  changeSort: index => dispatch(operations.changeSort(index)),
  changeCorrection: (productId, qty) => dispatch(operations.changeCorrection(productId, qty)),
  authEmployee: value => dispatch(authEmployee(value))
});

export default connect(mapState, mapDispatch)(StockTable);
