import { createSelector } from 'reselect';

export const getModule = state => state.app;

export const isStarted = state => getModule(state).started;
export const isDbReady = state => getModule(state).db.ready;
export const getSwUpdateAvailable = state => getModule(state).swUpdateAvailable;
export const isStockListSeen = state => getModule(state).isStockListSeen;
export const getEmployee = state => getModule(state).employee;

export const getProductsData = state => getModule(state).data.products;
export const getInventoriesData = state => getModule(state).data.inventories;
export const getStockData = state => getModule(state).data.stock;
export const getItemsData = state => getModule(state).data.items;
export const getCorrectionsData = state => getModule(state).data.corrections;
export const isInventoryDataFetching = state =>
  getStockData(state).fetching || getItemsData(state).fetching || getCorrectionsData(state).fetching;
export const isInventoryDataError = state =>
  getStockData(state).error || getItemsData(state).error || getCorrectionsData(state).error;
export const isInventoryDataUpdated = state =>
  getStockData(state).lastUpdated && getItemsData(state).lastUpdated && getCorrectionsData(state).lastUpdated;

export const hasProductsData = state => getProductsData(state).lastUpdated !== null && !getProductsData(state).fetching;
export const hasInventoriesData = state => getModule(state).data.inventories.lastUpdated !== null;

export const isProductsOffline = state => getProductsData(state).offline;

export const isDataReady = createSelector([hasProductsData, hasInventoriesData], (hasProducts, hasInventories) => {
  return hasProducts && hasInventories;
});

export const isDataError = state => getModule(state).data.products.error || getModule(state).data.inventories.error;

export const getActiveInventory = state => getModule(state).inventory;

export const getAvailableInventories = state => getModule(state).data.inventories.items;

export const isInventoriesFetching = state => getModule(state).data.inventories.fetching;

export const isUploadFetching = state => getModule(state).upload.fetching;

export const getUploadError = state => getModule(state).upload.error;
export const getUploadConfirmable = state => getModule(state).upload.confirmable;

export const getAlertShow = state => getModule(state).alert.show;
export const getAlertTitle = state => getModule(state).alert.title;
export const getAlertMessage = state => getModule(state).alert.message;

export default {
  getModule,
  isStarted,
  isDbReady,
  getSwUpdateAvailable,
  isStockListSeen,
  getEmployee,
  isProductsOffline,
  isDataReady,
  isDataError,
  getProductsData,
  getInventoriesData,
  getStockData,
  getActiveInventory,
  getAvailableInventories,
  isInventoriesFetching,
  isUploadFetching,
  getUploadError,
  getUploadConfirmable,
  getAlertShow,
  getAlertTitle,
  getAlertMessage
};
