import actions from './actions';
import selectors from './selectors';
import scannedProductsRepository from 'app/db/model/scanned-products';
import { config as tableConfig } from '../../../modules/products-overview/table/config';

export const fetch = () => async (dispatch, getState) => {
  const { columns } = tableConfig;
  const state = getState();

  dispatch(actions.fetch());

  const filter = selectors.getFilter(state);
  const page = selectors.getPage(state);
  const length = selectors.getLength(state);
  const order = selectors.getSort(state);

  const totalCount = await scannedProductsRepository.builder().count();

  const builder = scannedProductsRepository.builder().toCollection();

  if (filter.search) {
    builder.filter(item => {
      if (!filter.property) {
        let match = false;

        for (const column of columns) {
          if (
            String(item[column.property])
              .toUpperCase()
              .search(filter.search.toUpperCase()) > -1
          ) {
            match = true;
            break;
          }
        }

        return match;
      }

      return (
        String(item[filter.property])
          .toUpperCase()
          .search(filter.search.toUpperCase()) > -1
      );
    });
  }

  let rows;

  if (order.length) {
    if (order[0].dir === 'desc') {
      builder.reverse();
    }

    rows = await builder.sortBy(columns[order[0].column].property);
  } else {
    rows = await builder.toArray();
  }

  const filteredCount = rows.length;

  const data = {
    recordsTotal: totalCount,
    recordsFiltered: filteredCount,
    data: rows.slice(page * length, (page + 1) * length)
  };

  dispatch(actions.receive(data));
};

export const changePage = page => dispatch => {
  dispatch(actions.changePage(page));
  dispatch(fetch());
};

export const changePageLength = length => dispatch => {
  dispatch(actions.changePageLength(length));
  dispatch(fetch());
};

export const changeSort = index => dispatch => {
  dispatch(actions.changeSort(index));
  dispatch(fetch());
};

export const changeFilter = (search, property) => (dispatch, getState) => {
  const prevSearch = selectors.getFilterSearch(getState());

  dispatch(actions.changeFilter(search, property));

  if (!search && search === prevSearch) {
    return;
  }

  dispatch(fetch());
};

export default {
  fetch,
  changePage,
  changePageLength,
  changeSort,
  changeFilter
};
