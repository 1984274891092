import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { operations, selectors } from 'redux/modules/manual-count/items';
import DataTable from 'modules/util/datatable/paginated-datatable';
import { config } from './config';

export class ItemsTable extends Component {
  static propTypes = {
    fetching: PropTypes.bool,
    page: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
    filteredCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    data: PropTypes.array,
    fetchData: PropTypes.func.isRequired,
    changePage: PropTypes.func.isRequired,
    changeSort: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    // Hack to get a reference of this class in the column render method
    config.columns[5].render = (value, item) => {
      return (
        <IconButton onClick={this.handleRemoveClick.bind(this, item)}>
          <DeleteIcon />
        </IconButton>
      );
    };
  }

  componentDidMount() {
    this.props.fetchData();
  }

  handleRemoveClick = item => {
    const { onRemoveItem } = this.props;

    onRemoveItem && onRemoveItem(item);
  };

  render() {
    const { fetching, data, page, length, filteredCount, totalCount, changePage, changeSort } = this.props;

    return (
      <div>
        <DataTable
          fetching={fetching}
          data={data}
          page={page}
          length={length}
          filteredCount={filteredCount}
          totalCount={totalCount}
          onPageChange={changePage}
          onSort={changeSort}
          {...config}
        />
      </div>
    );
  }
}

const mapState = state => ({
  fetching: selectors.getFetching(state),
  page: selectors.getPage(state),
  length: selectors.getLength(state),
  filteredCount: selectors.getFilteredCount(state),
  totalCount: selectors.getTotalCount(state),
  data: selectors.getData(state)
});

const mapDispatch = dispatch => ({
  fetchData: () => dispatch(operations.fetch()),
  changePage: page => dispatch(operations.changePage(page)),
  changeSort: index => dispatch(operations.changeSort(index))
});

export default connect(mapState, mapDispatch)(ItemsTable);
