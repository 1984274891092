export const ADDING_ITEM = 'inventory/ADDING_ITEM';
export const ADD_ITEM = 'inventory/ADD_ITEM';
export const INCREASE_ITEM = 'inventory/INCREASE_ITEM';
export const REDUCE_ITEM = 'inventory/REDUCE_ITEM';
export const REMOVE_ITEM = 'inventory/REMOVE_ITEM';
export const CLEAR_ITEMS = 'inventory/CLEAR_ITEMS';
export const UPDATE_LAST_ITEM_AMOUNT = 'inventory/UPDATE_LAST_ITEM_AMOUNT';

export default {
  ADDING_ITEM,
  ADD_ITEM,
  INCREASE_ITEM,
  REDUCE_ITEM,
  REMOVE_ITEM,
  CLEAR_ITEMS,
  UPDATE_LAST_ITEM_AMOUNT
};
