import successSound from '../media/sound/scan.mp3';
import errorSound from '../media/sound/beep.mp3';

// iOS blocks autoplay audio by default, so we need this workaround
const beepAudio = new Audio();

document.addEventListener('click', function () {
  beepAudio.play();
}, { once: true });

const playSound = file => {
  beepAudio.src = file;
  beepAudio.play();
};

export const playScanSuccessSound = () => {
  playSound(successSound);
}

export const playScanErrorSound = () => {
  playSound(errorSound);
};
