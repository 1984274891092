import Dexie from 'dexie';

const db = new Dexie('app_data');

db.version(1).stores({
  products: 'id,ean,parma_number'
});

db.version(2).stores({
  scanned_items: 'id,date,type,product.id',
  scanned_products: 'id'
});

db.version(3).stores({
  results: 'id,inventory_id'
});

db.version(4).stores({
  stock: 'product_id',
  correction: 'product_id'
});

db.version(5).stores({
  scanned_items: 'id,date,type,product.id,synced'
});

if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
  window.db = db;
}

export default db;
