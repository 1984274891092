export const FETCH = 'stock/FETCH';
export const RECEIVE = 'stock/RECEIVE';
export const CHANGE_PAGE = 'stock/CHANGE_PAGE';
export const CHANGE_PAGE_LENGTH = 'stock/CHANGE_PAGE_LENGTH';
export const CHANGE_SORT = 'stock/CHANGE_SORT';
export const CHANGE_FILTER = 'stock/CHANGE_FILTER';
export const CHANGE_COLUMN_DATA = 'stock/CHANGE_COLUMN_DATA';
export const FETCH_STATISTICS_START = 'stock/FETCH_STATISTICS_START';
export const FETCH_STATISTICS_ERROR = 'stock/FETCH_STATISTICS_ERROR';
export const FETCH_STATISTICS_FINISH = 'stock/FETCH_STATISTICS_FINISH';

export default {
  FETCH,
  RECEIVE,
  CHANGE_PAGE,
  CHANGE_PAGE_LENGTH,
  CHANGE_SORT,
  CHANGE_FILTER,
  CHANGE_COLUMN_DATA,
  FETCH_STATISTICS_START,
  FETCH_STATISTICS_ERROR,
  FETCH_STATISTICS_FINISH
};
