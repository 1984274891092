import { combineReducers } from 'redux';

import * as types from './types';
import { FINISH, START } from '../app/types';

const initialState = {
  fetching: false,
  error: null,
  page: 0,
  filteredCount: 0,
  totalCount: 0,
  length: 10,
  data: null,
  sort: [
    {
      column: 0,
      dir: 'asc'
    }
  ],
  filter: {
    search: '',
    property: ''
  },
  statistics: {
    fetching: false,
    error: null,
    data: null
  }
};

const fetching = (state = initialState.fetching, action) => {
  switch (action.type) {
    case types.FETCH:
      return true;

    case types.RECEIVE:
      return false;

    default:
      return state;
  }
};

const data = (state = initialState.data, action) => {
  switch (action.type) {
    case types.RECEIVE:
      return action.payload.data.data;

    case types.CHANGE_COLUMN_DATA:
      const newData = [...state];
      for (const rowKey in newData) {
        if (newData[rowKey][action.payload.idField] === action.payload.idValue) {
          newData[rowKey][action.payload.changeField] = action.payload.changedValue;
        }
      }
      return newData;

    default:
      return state;
  }
};

const page = (state = initialState.page, action) => {
  switch (action.type) {
    case types.CHANGE_PAGE:
      return action.payload.page;

    case types.CHANGE_FILTER:
    case types.CHANGE_SORT:
      return 0;

    default:
      return state;
  }
};

const length = (state = initialState.length, action) => {
  switch (action.type) {
    case types.CHANGE_PAGE_LENGTH:
      return action.payload.length;
    default:
      return state;
  }
};

const sort = (state = initialState.sort, action) => {
  switch (action.type) {
    case types.CHANGE_SORT: {
      const current = state.find(entry => entry.column === action.payload.index);
      const direction = current && current.dir === 'asc' ? 'desc' : 'asc';

      return [
        {
          column: action.payload.index,
          dir: direction
        }
      ];
    }

    default:
      return state;
  }
};

const filter = (state = initialState.filter, action) => {
  switch (action.type) {
    case types.CHANGE_FILTER:
      return {
        search: action.payload.search,
        property: action.payload.property
      };

    default:
      return state;
  }
};

const filteredCount = (state = initialState.filteredCount, action) => {
  switch (action.type) {
    case types.RECEIVE:
      return action.payload.data.recordsFiltered;

    default:
      return state;
  }
};

const totalCount = (state = initialState.totalCount, action) => {
  switch (action.type) {
    case types.RECEIVE:
      return action.payload.data.recordsTotal;

    default:
      return state;
  }
};

const statistics = (state = initialState.statistics, action) => {
  switch (action.type) {
    case types.FETCH_STATISTICS_START:
      return { ...state, fetching: true };

    case types.FETCH_STATISTICS_FINISH:
      return { ...state, fetching: false, data: action.payload.data };

    case types.FETCH_STATISTICS_ERROR:
      return { ...state, fetching: false, error: action.payload.error };

    case START:
    case FINISH:
      return { ...initialState.statistics };

    default:
      return state;
  }
};

const rootReducer = combineReducers({
  fetching,
  data,
  page,
  length,
  sort,
  filter,
  filteredCount,
  totalCount,
  statistics
});

export default rootReducer;
