import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import history from 'config/history';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/es/integration/react';

import App from 'modules/app';
import AppAlert from 'modules/app/alert';
import UpdateAvailable from 'modules/app/update-available';
import InitLoader from 'modules/app/init-loader';

import './styles.modules.css';
import theme from 'config/theme';

const Root = ({ store, persistor, onBeforeLift }) => {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <PersistGate persistor={persistor} onBeforeLift={onBeforeLift} loading={<InitLoader />}>
            <Router history={history}>
              <div>
                <App />
                <AppAlert />
                <UpdateAvailable />
              </div>
            </Router>
          </PersistGate>
        </ThemeProvider>
      </MuiThemeProvider>
    </Provider>
  );
};

export default Root;
