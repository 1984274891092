import { combineReducers } from 'redux';
import itemsReducer from './items';

import * as types from './types';

const initialState = {
  manual: {
    next: {
      group: null,
      name: null,
      price: null,
      amount: null,
      reduction: {
        old: false,
        damaged: false,
        fashion: false,
        unsaleable: false
      }
    }
  }
};

const manual = (state = initialState.manual, action) => {
  switch (action.type) {
    case types.MANUAL_CHANGE_NEXT: {
      return {
        ...state,
        next: {
          ...state.next,
          ...action.payload.values
        }
      };
    }

    case types.MANUAL_CLEAR_NEXT: {
      return {
        ...state,
        next: {
          ...initialState.manual.next
        }
      };
    }

    default:
      return state;
  }
};

const manualCountReducer = combineReducers({
  manual,
  items: itemsReducer
});

export default manualCountReducer;
