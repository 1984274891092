export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

export const PRODUCT_GROUPS = {
  1: 'Pflege',
  2: 'Dekorative',
  4: 'Herren',
  6: 'Aktionsware',
  7: 'Galanterie',
  8: 'Bademode',
  9: 'Mod. Acess. Leder',
  10: 'DOB',
  15: 'Dessous'
};

export const MSG_ERROR_INTERNAL = 'Es ist ein Server Fehler aufgetreten.';
export const MSG_ERROR_CONFLICT =
  'Die ausgewählte Inventur ist einer anderen Filiale zugeordnet. Bitte Tablet in der richtigen Filiale benutzen. Sie haben die Möglichkeit, die vorliegenden Daten zu löschen und eine neue Inventur zu starten.';
export const MSG_ERROR_GONE =
  'Die ausgewählte Inventur ist bereits beendet. Sie haben die Möglichkeit, die vorliegenden Daten zu löschen und eine neue Inventur zu starten.';
export const MSG_ERROR_CONNECTION =
  'Es ist ein Verbindungsfehler aufgetreten. Stellen Sie sicher, dass das Tablet in der Filiale benutzt wird und eine Internetverbindung besteht.';
