export const getState = state => state.inventory;

export const getFetching = state => getState(state).fetching;

export const getLastItem = state => getState(state).lastItem;

export default {
  getState,
  getFetching,
  getLastItem
};
