import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import { routerMiddleware } from 'react-router-redux';
import history from 'config/history';
import { persistStore } from 'redux-persist';

let enhancer = compose(applyMiddleware(routerMiddleware(history), thunk));

if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  enhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    name: 'Inventur'
  })(enhancer);
}

export default function configureStore(initialState = {}) {
  const store = createStore(rootReducer, initialState, enhancer);
  const persistor = persistStore(store);

  return { persistor, store };
}
