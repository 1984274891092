import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Input, Select, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '../../text-field';

const Container = styled.div`
  display: flex;
  padding: 0 0 25px;
`;

const FilterInputContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: -16px;
`;

const FilterInput = styled(TextField)`
  flex: 1;
`;

const GapLabel = styled(Typography)`
  line-height: 32px !important;
  padding: 0 20px;
`;

const FilterSelect = styled(Select)`
  width: 120px;
  height: 32px;
`;

const RemoveFilterButton = styled(Button)`
  min-height: 33px !important;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  margin-left: 16px !important;
  margin-top: -4px !important;
`;

const RemoveFilterIcon = styled(ClearIcon)`
  margin: -5px 0 -5px -5px;
  color: ${props => props.theme.palette.primary.main};
`;

export default class Filter extends Component {
  static propTypes = {
    search: PropTypes.string,
    property: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ).isRequired,
    onChange: PropTypes.func
  };

  static defaultProps = {
    search: '',
    property: ''
  };

  state = {
    currentSearch: ''
  };

  getDerivedStateFromProps(props, state) {
    return {
      currentSearch: props.search
    };
  }

  handleFilterChange = event => {
    this.setState({ currentSearch: event.target.value });
  };

  handleFilterSubmit = () => {
    const { onChange, property } = this.props;

    onChange && onChange(this.state.currentSearch, property);
  };

  handlePropertyChange = event => {
    const { onChange } = this.props;
    const { currentSearch } = this.state;

    onChange && onChange(currentSearch, event.target.value);
  };

  handleClear = () => {
    const { onChange, search, property } = this.props;

    this.setState({ currentSearch: '' });

    if (search || property) {
      onChange && onChange('', '');
    }
  };

  render() {
    const { property, options } = this.props;
    const { currentSearch } = this.state;

    return (
      <Container>
        <FilterInputContainer>
          <FilterInput
            label="Filtern nach"
            value={currentSearch}
            onChange={this.handleFilterChange}
            onSubmit={this.handleFilterSubmit}
          />
        </FilterInputContainer>
        <GapLabel>in</GapLabel>
        <FilterSelect native value={property} onChange={this.handlePropertyChange} input={<Input />}>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </FilterSelect>
        <RemoveFilterButton onClick={this.handleClear}>
          <RemoveFilterIcon>
            <ClearIcon />
          </RemoveFilterIcon>
          Filter aufheben
        </RemoveFilterButton>
      </Container>
    );
  }
}
