import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { operations as appOperations, selectors as appSelectors } from 'redux/modules/app';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Inventories from './inventories';

import styles from './styles.module.css';

export class Start extends Component {
  static propTypes = {
    inventories: PropTypes.array.isRequired,
    fetching: PropTypes.bool,
    onStart: PropTypes.func.isRequired
  };

  state = {
    selectedInventory: null
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.inventories !== nextProps.inventories) {
      if (nextProps.inventories.length === 1) {
        this.setState({ selectedInventory: nextProps.inventories[0] });
      } else {
        this.setState({ selectedInventory: null });
      }
    }
  }

  onStart = e => {
    e.preventDefault();

    const { onStart } = this.props;
    const { selectedInventory } = this.state;

    if (!selectedInventory) {
      return;
    }

    onStart(selectedInventory);
  };

  handleInventoryChange = selectedInventory => {
    this.setState({ selectedInventory: selectedInventory });
  };

  render() {
    const { inventories, fetching } = this.props;
    const { selectedInventory } = this.state;

    return (
      <Grid container alignItems="center" direction="row" justify="center" spacing={0} className={styles.gridContainer}>
        <Grid item>
          <Paper elevation={3} square>
            <div className={styles.startContainer}>
              <Inventories
                fetching={fetching}
                inventories={inventories}
                selectedInventory={selectedInventory}
                onChange={this.handleInventoryChange}
              />
              <form onSubmit={this.onStart}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={styles.btn}
                  disabled={fetching || !selectedInventory}
                >
                  Start
                </Button>
              </form>
            </div>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    inventories: appSelectors.getAvailableInventories(state),
    fetching: appSelectors.isInventoriesFetching(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onStart: inventory => {
      dispatch(appOperations.start(inventory));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Start);
