import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { selectors as appSelectors } from 'redux/modules/app';
import DataLoader from './data-loader';
import Start from './start';
import WorkArea from './work-area';

export class App extends Component {
  render() {
    const { dataReady, started } = this.props;

    if (!dataReady) {
      return <DataLoader />;
    }

    if (!started) {
      return <Start />;
    }

    return <WorkArea />;
  }
}

const mapStateToProps = state => {
  return {
    dataReady: appSelectors.isDataReady(state),
    started: appSelectors.isStarted(state)
  };
};

export default withRouter(connect(mapStateToProps)(App));
